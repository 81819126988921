import React, { FC, useState, useRef, useEffect } from 'react';

import cn from 'classnames';

import { useUpdateEffect } from 'utils/customHooks';
import video from 'assets/images/fractalVideo.mp4';
import Image from 'components/atoms/Image/Image';

import ProductSliderHighlights from './ProductSliderHighlights';
import ProductSliderImage from './ProductSliderImage';
import { Trans } from '@lingui/macro';
import { Link } from 'gatsby';

type ProductSliderProps = {
  hightlights: string[];
  title: string;
  subTitle: string;
  imageNames: string[];
  timeout?: number;
  delayBeforeRestart?: number;
};

const ProductSlider: FC<ProductSliderProps> = ({
  hightlights,
  title,
  subTitle,
  imageNames,
  delayBeforeRestart = 4000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const videoElement = useRef<HTMLVideoElement>(null);
  const [pageWindow] = useState(typeof window !== 'undefined' ? window : ({} as Window));
  const [pageWidth, setPageWidth] = useState(pageWindow.innerWidth);
  const isMobile = pageWidth < 768;
  const isDesktop = pageWidth > 1024;

  useUpdateEffect(() => {
    setPageWidth(window.innerWidth);
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [pageWindow.innerWidth]);

  useUpdateEffect(() => {
    setTimeout(() => {
      setHoveredIndex(-1);
    }, delayBeforeRestart);
  }, [hoveredIndex]);

  useEffect(() => {
    if (videoElement != null && videoElement.current != null) {
      videoElement.current.ontimeupdate = () =>
        videoElement != null
          ? videoElement.current != null
            ? getIndexFromTime(videoElement.current.currentTime)
            : {}
          : {};
    }
  }, [videoElement.current?.currentTime]);

  const goToVideoTime = (time: number) => {
    if (!document) {
      return;
    }
    if (videoElement == null) {
      return;
    }
    if (videoElement.current === null) {
      return;
    }
    videoElement.current.currentTime = time ?? timeMap[currentIndex];
  };

  useUpdateEffect(() => {
    if (hoveredIndex === -1) {
      return;
    }

    const currentTime = videoElement.current?.currentTime || timeMap[hoveredIndex];
    if (currentTime >= timeMap[hoveredIndex] && currentTime < timeMap[hoveredIndex + 1]) {
      return;
    }
    goToVideoTime(timeMap[hoveredIndex]);
  }, [hoveredIndex]);

  const index = hoveredIndex !== -1 ? hoveredIndex : currentIndex;

  const timeMap: Record<number, number> = {
    0: 0,
    1: 35,
    2: 85,
    3: 112,
    4: 122,
    5: 131,
    6: 143,
    7: 150,
  };

  const getIndexFromTime = (timeToMap: number) => {
    Object.values(timeMap).forEach((value, i) => {
      if (timeToMap >= value && timeToMap < timeMap[i + 1] && currentIndex !== i) {
        setCurrentIndex(i);
      }
    });
  };

  const mobileImages = [
    'fractal-product-0',
    'fractal-product-1',
    'fractal-product-2',
    'fractal-product-3',
    'fractal-product-4',
    'fractal-product-5',
    'fractal-product-6',
  ];

  return (
    <div className="content-block">
      <video
        width="750"
        height="500"
        autoPlay
        loop
        muted
        ref={videoElement}
        // className={cn('w-0 md:w-full', { 'mt-[50px]': isDesktop }, { hidden: isMobile })} // TODO: Decomment this part
        className={cn('w-[100%]', { 'mt-[50px]': isDesktop })}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="content-block__info">
        <div className="flex mb-m">
          <Image name="fractal-logo" className="w-[56px] h-[56px] min-w-[56px] mr-xs" />
          <div>
            <div className="text-[24px] font-semibold text-base-900 font-sans">{title}</div>
            <div className={cn('text-[20px] text-base-800', { 'text-[14px]': isMobile })}>
              {subTitle}
            </div>
          </div>
        </div>
        {/* <div className={cn({ hidden: !isMobile })}>
          <ProductSliderImage titles={hightlights} images={mobileImages} />
        </div> */}
        {/* <div className={cn({ hidden: isMobile })}> */}
        <ProductSliderHighlights index={index} highlights={hightlights} onClick={setHoveredIndex} />
        {/* </div> */}
        <Link
          to="/fractal"
          className="m-auto tablet-up:ml-[0px] bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between"
        >
          <span className="text-white">
            <Trans>Read More</Trans>
          </span>
          <Image name="arrow-icon-white" className="fill-white mr-s" />
        </Link>
      </div>
      <div className="content-block__info"></div>
    </div>
  );
};

export default ProductSlider;
